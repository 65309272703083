import React, { useRef } from 'react';
import MapScreenShotButton from "../../UI/DynamicScreenshotCard/MapScreenShotButton/MapScreenShotButton";
import MaximizeIcon from "../components/svg/MaximizeIcon";
import CloseIcon from "../components/svg/CloseIcon";
import MinimizeIcon from "../components/svg/MinimizeIcon";

const FullScreenGalleryItem = ({
                                   getPageFromUrl,
                                   isPresentation,
                                   articleId,
                                   fromArticle,
                                   currentIndex,
                                   mapState,
                                   toggleFullScreen,
                                   toggleGallery,
                                   fullScreenRef
                               }) => {

    return (
        <div className="full-screen-gallery-item">
            <div className="gallery-header full-screen-header">
                {getPageFromUrl() !== 'true' && (
                    isPresentation ? (
                        <MapScreenShotButton
                            articleId={articleId}
                            type='presentation-article'
                            isReadMoreArticle={fromArticle}
                            galleryFullScreen={true}
                            galleryIndex={currentIndex}
                            map={mapState}
                            article={true}
                            white={true}
                        />
                    ) : (
                        <MapScreenShotButton
                            articleId={articleId}
                            isReadMoreArticle={fromArticle}
                            galleryFullScreen={true}
                            galleryIndex={currentIndex}
                            map={mapState}
                            article={true}
                            white={true}
                        />
                    )
                )}
                <button
                    className="gallery-header-btn gallery-maximize"
                    type="button"
                    onClick={() => toggleFullScreen(false)}
                >
                    <MinimizeIcon />
                </button>
                <button
                    className="gallery-header-btn gallery-close"
                    type="button"
                    onClick={toggleGallery}
                >
                    <CloseIcon />
                </button>
            </div>
            <div className="gallery-content-full-screen">
                <div className="full-screen" ref={fullScreenRef}></div>
            </div>
        </div>
    );
};

export default FullScreenGalleryItem;
