import React, {memo, useEffect, useRef} from 'react';
import {connect} from "react-redux";
import {isMobile} from "react-device-detect";
import { v4 as uuidv4 } from 'uuid';

import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";

import {
    setCommentToolboxState,
    setEraserClickedState,
    setLineDrawToolboxState,
    setRulerClickedState,
    setDrawerToolboxClickedState,
    setStickyNotesClickedState, setPolygonDrawToolboxState
} from "../../../store/actions/painterStart";
import {
    dispatchUndoRedoData,
    setRulerGeoJsonDataST,
    setRulerPopupShowST,
    setSelectedToolUndoRedo,
} from "../../../store/actions/mapStateAction";
import {
    getIsAllToolboxStatesLoaded,
    getMapStyledDarkId,
    getBaseMapGroupIdST,
    getMobileMenuState,
    getPainterStartData,
    getRulerClickedState,
    getRulerGeoJsonDataST,
    getRulerPopupShowST,
    getDrawerToolboxClickedState
} from "../../../store/selectors";

import {
    changeCursorIcon,
    CURSOR_TYPE,
    getRulerPopupTemplate,
} from "../../../shared/mockData";
import {RULER_LAYER_AND_SOURCE_NAME, RULER_POINTS_LAYER_NAME} from "../DrawerToolbox/constants";
import {MAP_TOOLBOX_KEYS} from "../../mapToolbox";

import RulerIcon from "../../../assets/imgs/PaintBar/ruler-icon.png";
import {mapboxGlDrawStyles} from "../MapboxDrawToolbox/constants";
import {useLocation} from "react-router-dom";

const markerHeight = 10;
const markerRadius = 10;
const linearOffset = 12;
const adjustment = 45;

const popupOffsets = {
    'top': [(markerHeight + linearOffset + markerRadius) * 2 + adjustment, 0],
    'top-left': [linearOffset / 2, 0],
    'top-right': [-(linearOffset / 2), 0],
    'bottom': [(markerHeight + linearOffset + markerRadius) * 2 + adjustment, (linearOffset) * -1],
    'bottom-left': [markerRadius + adjustment, (markerHeight + linearOffset - markerRadius) * -1],
    'bottom-right': [-markerRadius, (markerHeight + linearOffset - markerRadius) * -1],
    'left': [markerRadius + adjustment, (markerHeight + linearOffset - markerRadius) * -1],
    'right': [-markerRadius + adjustment, (markerHeight + linearOffset - markerRadius) * -1],
};

const RulerToolbox = (props) => {
    const {
        getPainterStartData,
        getRulerClickedState,
        getRulerGeoJsonDataST,
        setCommentToolboxState,
        setLineDrawToolboxState,
        setDrawerToolboxClickedState,
        setEraserClickedState,
        setRulerClickedState,
        setPolygonDrawToolboxState,
        setRulerGeoJsonDataST,
        setRulerPopupShowST,
        setSelectedToolUndoRedo,
        dispatchUndoRedoData,
        getIsAllToolboxStatesLoaded,
        setStickyNotesClickedState,
        hasLineOrPolygonDraw,
        isScreenShot,
        baseMapGroupIdST,
        map,
    } = props;

    const getRulerSource = () => {
        return map.getSource(RULER_LAYER_AND_SOURCE_NAME);
    };

    const lineStringRef = useRef(null);
    const rulerGeojsonRef = useRef({
        type: "FeatureCollection",
        features: [],
    });

    const popupRef = useRef(null);
    const popupActiveRef = useRef(null);
    const rulerClickHandlerRef = useRef(null);
    const handleMouseEnterOnPointRef = useRef(null);
    const handleMouseLeaveOnPointRef = useRef(null);
    const handlePopupOnOpenFunctionRef = useRef(null);
    const handlePopupOnCloseFunctionRef = useRef(null);
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const toggleMouseEnterAndOutHandlers = (bool = true) => {
        if (bool) {
            handleMouseEnterOnPointRef.current = handleMouseEnterOnPoint;
            handleMouseLeaveOnPointRef.current = handleMouseOutFromPoint;
            if (!isMobile) {
                map.on("mouseenter", RULER_POINTS_LAYER_NAME, handleMouseEnterOnPointRef.current);
                map.on("mouseleave", RULER_POINTS_LAYER_NAME, handleMouseLeaveOnPointRef.current);
            } else {
                map.on("touchstart", RULER_POINTS_LAYER_NAME, handleMouseEnterOnPointRef.current);
                map.on("touchend", RULER_POINTS_LAYER_NAME, handleMouseLeaveOnPointRef.current);
            }
        } else {
            if (!isMobile) {
                map.off("mouseenter", RULER_POINTS_LAYER_NAME, handleMouseEnterOnPointRef.current);
                map.off("mouseleave", RULER_POINTS_LAYER_NAME, handleMouseLeaveOnPointRef.current);
            } else {
                map.off("touchstart", RULER_POINTS_LAYER_NAME, handleMouseEnterOnPointRef.current);
                map.off("touchend", RULER_POINTS_LAYER_NAME, handleMouseLeaveOnPointRef.current);
            }
        }
    }

    const toggleClickFunction = (bool = true) => {
        if (bool) {
            rulerClickHandlerRef.current = rulerClickHandler;
            map.on(isMobile ? "touchstart" : "click", rulerClickHandlerRef.current);
        } else {
            map.off(isMobile ? "touchstart" : "click", rulerClickHandlerRef.current);
        }
    }

    const removePopup = () => {
        popupRef.current?.remove();
        popupActiveRef.current?.remove();
    }

    const handlePopupOpen = () => setRulerPopupShowST(true);

    const handlePopupClose = () => setRulerPopupShowST(false);

    const rulerClickHandler = (e) => {
        if(!lineStringRef.current) {
            lineStringRef.current = {
                type: "Feature",
                geometry: {
                    type: "LineString",
                    coordinates: [],
                },
            };

            rulerGeojsonRef.current.features.push(lineStringRef.current);
        }

        const renderedRulerFeatures = map.queryRenderedFeatures(e.point, {
            layers: [RULER_POINTS_LAYER_NAME],
        });

        if(renderedRulerFeatures.length > 0) return;

        const { lng, lat } = e.lngLat;

        lineStringRef.current.geometry.coordinates.push([lng, lat]);

        const point = {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [e.lngLat.lng, e.lngLat.lat],
            },
            properties: {
                id: uuidv4(),
                cordPath: rulerGeojsonRef.current.features && rulerGeojsonRef.current.features.length,
                distance: turf.length(lineStringRef.current),
                description: `Ruler point ${rulerGeojsonRef.current.features.length}`,
            },
        };

        rulerGeojsonRef.current.features.push(point);
        getRulerSource() && getRulerSource().setData(rulerGeojsonRef.current);
        setRulerGeoJsonDataST(JSON.parse(JSON.stringify(rulerGeojsonRef.current)));
        dispatchUndoRedoData({...JSON.parse(JSON.stringify(getRulerSource()._data))})

        if (point.properties.distance === 0) return;

        const popupContent = getRulerPopupTemplate(point?.properties?.distance).outerHTML;

        popupActiveRef.current
            .setLngLat(point.geometry.coordinates)
            .setHTML(`${popupContent}`)
            .addTo(map);

        popupActiveRef.current.on('open', handlePopupOnOpenFunctionRef.current);
        popupActiveRef.current.on('close', handlePopupOnCloseFunctionRef.current);

        document.querySelector('.close_icon').addEventListener('click', removePopup);
    }

    const removeRuler = () => {
        rulerGeojsonRef.current = {
            type: "FeatureCollection",
            features: [],
        };
        if(lineStringRef.current) lineStringRef.current = null;
        getRulerSource() && getRulerSource().setData(rulerGeojsonRef.current);
        setRulerGeoJsonDataST(JSON.parse(JSON.stringify(rulerGeojsonRef.current)));
        removePopup();
    };

    const handleMouseEnterOnPoint = (e) => {
        toggleClickFunction(false);
        map.getCanvas().style.cursor = 'pointer';
        const point = e.features[0];

        if ((point.properties.cordPath === rulerGeojsonRef.current.features.length - 1 && popupActiveRef.current.isOpen()) || point.properties.distance === 0) {
            return;
        }

        const popupContent = getRulerPopupTemplate(point?.properties?.distance).outerHTML;
        popupRef.current
            .setLngLat(point.geometry.coordinates)
            .setHTML(`${popupContent}`)
            .addTo(map);

        document.querySelector('.close_icon').addEventListener('click', removePopup);
    }

    const handleMouseOutFromPoint = (e) => {
        toggleClickFunction(true);
        map.getCanvas().style.cursor = '';
        popupRef.current?.remove();
    }

    const handleIconClick = () => {
        setDrawerToolboxClickedState(false);
        setEraserClickedState(false);
        setPolygonDrawToolboxState(false);
        setLineDrawToolboxState(false);
        setCommentToolboxState(false);
        setStickyNotesClickedState(false);
        setRulerClickedState(!getRulerClickedState);
    }

    const createSourceAndLayers = () => {
        if (!rulerGeojsonRef.current || Object.keys(rulerGeojsonRef.current).length === 0) {
            return;
        }
        if(!getRulerSource()) {
            map.addSource(RULER_LAYER_AND_SOURCE_NAME, {
                'type': 'geojson',
                'data': rulerGeojsonRef.current
            });
        }

        if(!map.getLayer(RULER_LAYER_AND_SOURCE_NAME)) {
            map.addLayer({
                id: RULER_LAYER_AND_SOURCE_NAME,
                type: "line",
                source: RULER_LAYER_AND_SOURCE_NAME,
                layout: {
                    "line-cap": "round",
                    "line-join": "round",
                },
                paint: {
                    "line-color": "#EB572C",
                    "line-width": 4,
                },
                filter: ["in", "$type", "LineString"],
            });
        }

        if(!map.getLayer(RULER_POINTS_LAYER_NAME)) {
            map.addLayer({
                id: RULER_POINTS_LAYER_NAME,
                type: "circle",
                source: RULER_LAYER_AND_SOURCE_NAME,
                paint: {
                    "circle-radius": 4.8,
                    "circle-color": "#fff",
                    "circle-stroke-color": "#EB572C",
                    "circle-stroke-opacity": 1,
                    "circle-stroke-width": 2.8,
                },
                filter: ["in", "$type", "Point"],
            });
        }

        // MOVE MAPBOX-DRAW LIBRARY CREATED LAYERS TO TOP OF THE Z-INDEX IF THEY EXIST
        // if (hasLineOrPolygonDraw) {
        //     mapboxGlDrawStyles.forEach(layer => {
        //         if (map.getLayer(`${layer.id + '.hot'}`) || map.getLayer(`${layer.id + '.cold'}`)) {
        //             map.moveLayer(`${layer.id + '.hot'}`);
        //             map.moveLayer(`${layer.id + '.cold'}`);
        //         }
        //     });
        // }
    }

    useEffect(() => {
        if (!rulerGeojsonRef.current) {
            return;
        }
        setTimeout(() => {
            createSourceAndLayers();
        }, 2000)
    }, [baseMapGroupIdST, rulerGeojsonRef.current])

    useEffect(() => {
        rulerClickHandlerRef.current = rulerClickHandler;
        handlePopupOnOpenFunctionRef.current = handlePopupOpen;
        handlePopupOnCloseFunctionRef.current = handlePopupClose;
        // createSourceAndLayers();

        popupRef.current = new mapboxgl.Popup({
            closeOnClick: true,
            closeButton: true,
            offset: popupOffsets,
            className: `ruler`,
        });

        popupActiveRef.current = new mapboxgl.Popup({
            closeOnClick: true,
            closeButton: true,
            offset: popupOffsets,
            className: `ruler-active`,
        });

        toggleMouseEnterAndOutHandlers(true);
    }, [])

    useEffect(() => {
        if (getRulerClickedState && getPainterStartData) {
            map?.on(`${isMobile ? "touchstart" : "click"}`, rulerClickHandlerRef.current);
            setTimeout(() => {
                changeCursorIcon(CURSOR_TYPE.RULER);
            }, 50)
            setSelectedToolUndoRedo(MAP_TOOLBOX_KEYS.RULER);
        } else {
            map?.off(`${isMobile ? "touchstart" : "click"}`, rulerClickHandlerRef.current);
            if(Object.keys(rulerGeojsonRef.current).length) removeRuler();
            changeCursorIcon();
            setSelectedToolUndoRedo(MAP_TOOLBOX_KEYS.DRAWER);
            setRulerClickedState(false);
        }
    }, [getRulerClickedState, getPainterStartData])

    useEffect(() => {
        rulerGeojsonRef.current = JSON.parse(JSON.stringify(getRulerGeoJsonDataST));
        if (!!rulerGeojsonRef.current.features?.length) {
            if(rulerGeojsonRef.current.features[0] && rulerGeojsonRef.current.features[0].geometry.type === "LineString") {
                lineStringRef.current = rulerGeojsonRef.current.features[0];
            } else {
                lineStringRef.current = rulerGeojsonRef.current.features.find(feature => feature.geometry.type === "LineString")
            }

            let lastPoint;

            if (rulerGeojsonRef.current.features) {
                lastPoint = rulerGeojsonRef.current?.features[rulerGeojsonRef.current?.features?.length - 1];
            }

            if(!lastPoint || !lastPoint?.properties?.distance) {
                popupActiveRef.current.remove();
                return
            };

            const popupContent = lastPoint && getRulerPopupTemplate(lastPoint.properties.distance || 0).outerHTML;

            popupActiveRef.current
                .setLngLat(lastPoint.geometry.coordinates)
                .setHTML(`${popupContent}`)
                .addTo(map);

            document.querySelector('.close_icon').addEventListener('click', removePopup)
        }
    }, [+query.get("screenSlide"), getRulerGeoJsonDataST,baseMapGroupIdST])

    useEffect(() => {
        if(!isScreenShot) return;
        if(!getIsAllToolboxStatesLoaded) return;
        if(!Object.keys(getRulerGeoJsonDataST).length) return;
            if(getRulerGeoJsonDataST && getRulerGeoJsonDataST.features && getRulerGeoJsonDataST.features[0] && getRulerGeoJsonDataST.features[0]?.type !== "LineString") {
                const oldScreenshotData = {...getRulerGeoJsonDataST};
                const newScreenshotData = {type: "FeatureCollection", features: []};
                const lineStringFeatureFromOldScreenshotData = oldScreenshotData.features.find(obj => obj.geometry.type === "LineString");
                if(lineStringFeatureFromOldScreenshotData && lineStringFeatureFromOldScreenshotData.geometry.coordinates.length) {
                    newScreenshotData.features.push(lineStringFeatureFromOldScreenshotData);
                    lineStringFeatureFromOldScreenshotData.geometry.coordinates.forEach((coords, index) => {
                        const editedLineString = {
                            ...lineStringFeatureFromOldScreenshotData,
                            geometry: {
                                ...lineStringFeatureFromOldScreenshotData.geometry,
                                coordinates: lineStringFeatureFromOldScreenshotData.geometry.coordinates.slice(0, index + 1),
                            }
                        };

                        const point = {
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: coords,
                            },
                            properties: {
                                id: String(new Date().getTime()),
                                cordPath: index + 1,
                                distance: turf.length(editedLineString),
                                description: `Ruler point ${index + 1}`,
                            },
                        };

                        newScreenshotData.features.push(point);
                    })
                }
                rulerGeojsonRef.current = {...newScreenshotData};
                setRulerGeoJsonDataST(JSON.parse(JSON.stringify(rulerGeojsonRef.current)))
            } else {
                rulerGeojsonRef.current = {...getRulerGeoJsonDataST};
            }

        let lastPoint;

        if (rulerGeojsonRef.current.features) {
            lastPoint = rulerGeojsonRef.current?.features[rulerGeojsonRef.current?.features?.length - 1];
        }

        if(!lastPoint) return;

        const popupContent = lastPoint && getRulerPopupTemplate(lastPoint.properties.distance || 0).outerHTML;

        popupActiveRef.current
            .setLngLat(lastPoint.geometry.coordinates)
            .setHTML(`${popupContent}`)
            .addTo(map);

            lineStringRef.current = rulerGeojsonRef.current.features.find(feature => feature.geometry.type === "LineString");

            document.querySelector('.close_icon').addEventListener('click', removePopup)
            getRulerSource() && getRulerSource().setData(rulerGeojsonRef.current);
    }, [isScreenShot, getIsAllToolboxStatesLoaded]);

    return (
        <div
            id="ruler_icon"
            className={`pain_items ${getRulerClickedState ? "button_active" : ""}`}
            onClick={handleIconClick}
        >
            <img src={RulerIcon} alt="" className="icon_img"/>
        </div>
    );
};

const mapStateToProps = (state) => ({
    getDrawerToolboxClickedState: getDrawerToolboxClickedState(state),
    getRulerClickedState: getRulerClickedState(state),
    getPainterStartData: getPainterStartData(state),
    getRulerGeoJsonDataST: getRulerGeoJsonDataST(state),
    getRulerPopupShowST: getRulerPopupShowST(state),
    getMapStyledDarkId: getMapStyledDarkId(state),
    getMobileMenuState: getMobileMenuState(state),
    getIsAllToolboxStatesLoaded: getIsAllToolboxStatesLoaded(state),
    baseMapGroupIdST: getBaseMapGroupIdST(state),
})
const mapDispatchToProps = {
    setCommentToolboxState: setCommentToolboxState,
    setLineDrawToolboxState: setLineDrawToolboxState,
    setDrawerToolboxClickedState: setDrawerToolboxClickedState,
    setRulerClickedState: setRulerClickedState,
    setStickyNotesClickedState: setStickyNotesClickedState,
    setPolygonDrawToolboxState: setPolygonDrawToolboxState,
    setEraserClickedState: setEraserClickedState,
    setRulerGeoJsonDataST: setRulerGeoJsonDataST,
    setRulerPopupShowST: setRulerPopupShowST,
    dispatchUndoRedoData: dispatchUndoRedoData,
    setSelectedToolUndoRedo: setSelectedToolUndoRedo,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(RulerToolbox));
