import { Provider } from "react-redux"
import { store } from "./store"
import Navigation from "./navigation"
import "./App.css"
import "./globals.css"
import {useState} from "react";
import UnderConstruction from "./components/underConstruction";

function App() {
    const [hasAccess, setHasAccess] = useState(true); // if need to close access in future set in state localStorage.getItem('acc_date')

    return (
        <Provider store={store}>
            {
                !!hasAccess ?  <Navigation /> : <UnderConstruction onAccessPassed={setHasAccess} />
            }
        </Provider>
  )
}

export default App