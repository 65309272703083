import {
  CHANGE_MAP_STYLES,
  CHANGE_MAP_STYLES_DARK,
  CHANGE_MAP_STYLES_LIST,
  SET_MAP_CONFIGURATIONS,
  SET_MAP_STYLES, SET_MAP_STYLES_EXTRA_LAYERS
} from "../constants";
import {setupBaseMapConfig, setupMapExtraLayers, setupMapStyles} from "../../service/mapStyles";



export const changeMapStyles = (id,isLight) => {
  return {
    type: CHANGE_MAP_STYLES,
    payload: id,
    isLight:isLight
  };
};
export const setMapStylesDark = (id,) => {
  return {
    type: CHANGE_MAP_STYLES_DARK,
    payload: id,
  };
};

export const setMapStylesList = (list) => {
  return {
    type: CHANGE_MAP_STYLES_LIST,
    payload: list,
  };
};
export const setupMapStylesData = (data) => {
  return {
    type: SET_MAP_STYLES,
    payload: data,
  };
};
export const setupBaseMapConfigData = (data) => {
  return {
    type: SET_MAP_CONFIGURATIONS,
    payload: data,
  };
};
export const getBaseMapsFromAPI = (body) => async (dispatch, getState) => {
  return setupMapStyles(body).then((res) => {
    dispatch(setupMapStylesData(res))
    return res
  })
}
export const getBaseMapConfigurationsFromAPI = (body) => async (dispatch, getState) => {
  return setupBaseMapConfig(body).then((res) => {
    dispatch(setupBaseMapConfigData(res))
    return res
  })
}

export const setMapStyleExtraLayers = (styleId) => async  (dispatch,states) => {
  return await  setupMapExtraLayers({
                  baseMapId: styleId || 1
                }).then(res=>{
                  const sortedLayers = [
                    'unclustered-point',
                    'unclustered-text',
                    'clusters',
                    'cluster-count'
                  ].map(item=>res.find(el=>el.data.id === item).data)
                  dispatch(setMapStyleExtraLayersData(sortedLayers))
                  return sortedLayers
                })
};
export const setMapStyleExtraLayersData = (data) => {
  return {
    type: SET_MAP_STYLES_EXTRA_LAYERS,
    payload: data
  }
}
