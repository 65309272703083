import React, { useEffect, memo} from 'react';
import {connect} from "react-redux";

import {
    getPolygonDrawToolboxState,
    getPolygonDrawToolboxColors,
    getPolygonDrawToolboxPolygonsState,
    getPainterStartData,
    getPolygonDrawToolboxCurrentStrokeColor,
    getPolygonDrawToolboxCurrentFillOpacity,
    getPolygonDrawToolboxCurrentFillColor, getPolygonDrawToolboxCurrentStrokeOpacity, getLineDrawToolboxState,
} from "../../../../store/selectors";

import {
    setEraserClickedState,
    setRulerClickedState,
    setDrawerToolboxClickedState,
    setCommentToolboxState,
    setPolygonDrawToolboxState,
    setPolygonDrawToolboxColors, setLineDrawToolboxState, setStickyNotesClickedState,
} from "../../../../store/actions/painterStart";

import {
    setMapboxDrawToolboxGeoJSONState,
    setPolygonDrawToolboxPolygonsState
} from "../../../../store/actions/mapStateAction";

import {changeCursorIcon} from "../../../../shared/mockData";

import PolygonDraw from "../../../../assets/imgs/PaintBar/polygon-draw-tool-icon.svg";
import "./style.css";
import "../../../PaintBar/style.css";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';


const PolygonDrawToolbox = (props) => {

    const {
        setPolygonDrawToolboxState,
        getPainterStartData,
        getPolygonDrawToolboxState,
        getLineDrawToolboxState,
        setDrawerToolboxClickedState,
        setEraserClickedState,
        setStickyNotesClickedState,
        setRulerClickedState,
        getPolygonDrawToolboxCurrentFillColor,
        getPolygonDrawToolboxCurrentFillOpacity,
        getPolygonDrawToolboxCurrentStrokeColor,
        getPolygonDrawToolboxCurrentStrokeOpacity,
        setMapboxDrawToolboxGeoJSONState,
        setLineDrawToolboxState,
        setCommentToolboxState,
        selectedMapboxDrawToolFeature,
        draw,
    } = props;

    const changeFeatureProps = (id) => {
        if (!draw) return;
        const thisFeature = draw.getSelected().features[0];
        if (thisFeature && thisFeature.properties) {
            draw.setFeatureProperty(id, 'portFillColor', getPolygonDrawToolboxCurrentFillColor);
            draw.setFeatureProperty(id, 'portFillOpacity', getPolygonDrawToolboxCurrentFillOpacity);
            draw.setFeatureProperty(id, 'portStrokeColor', getPolygonDrawToolboxCurrentStrokeColor);
            draw.setFeatureProperty(id, 'portStrokeOpacity', getPolygonDrawToolboxCurrentStrokeOpacity);
        }
        draw.add(draw.get(id));
        setMapboxDrawToolboxGeoJSONState(JSON.parse(JSON.stringify(draw.getAll())))
    }

    useEffect(() => {
        if(selectedMapboxDrawToolFeature) {
            changeFeatureProps(selectedMapboxDrawToolFeature.id);
        }
    }, [getPolygonDrawToolboxCurrentFillColor, getPolygonDrawToolboxCurrentFillOpacity, getPolygonDrawToolboxCurrentStrokeColor, getPolygonDrawToolboxCurrentStrokeOpacity]);


    const handleIconClick = () => {
        setCommentToolboxState(false);
        setDrawerToolboxClickedState(false);
        setStickyNotesClickedState(false);
        setEraserClickedState(false);
        setRulerClickedState(false);
        setLineDrawToolboxState(false);
        setPolygonDrawToolboxState(!getPolygonDrawToolboxState);
    }

    useEffect(() => {
        if(!draw || getLineDrawToolboxState) return;
        if (getPainterStartData && getPolygonDrawToolboxState) {
            setTimeout(() => {
                draw.changeMode('draw_polygon');
            }, 30)
        } else {
            if(!draw.getSelected().features.length) {
                draw.changeMode('simple_select');
                changeCursorIcon();
            }
        }
    }, [getPainterStartData, getPolygonDrawToolboxState, getLineDrawToolboxState]);

    return (
        <>
            <div
                id="lineDraw_icon"
                className={`pain_items ${getPolygonDrawToolboxState ? "button_active" : ""}`}
                onClick={handleIconClick}
            >
                <img src={PolygonDraw} alt="" className="icon_img"/>
            </div>
        </>
    );
};

const mapStateTopProps = (state) => ({
    getPainterStartData: getPainterStartData(state),
    getPolygonDrawToolboxState: getPolygonDrawToolboxState(state),
    getPolygonDrawToolboxPolygonsState: getPolygonDrawToolboxPolygonsState(state),
    getPolygonDrawToolboxColors: getPolygonDrawToolboxColors(state),
    getLineDrawToolboxState: getLineDrawToolboxState(state),
    getPolygonDrawToolboxCurrentFillColor: getPolygonDrawToolboxCurrentFillColor(state),
    getPolygonDrawToolboxCurrentFillOpacity: getPolygonDrawToolboxCurrentFillOpacity(state),
    getPolygonDrawToolboxCurrentStrokeColor: getPolygonDrawToolboxCurrentStrokeColor(state),
    getPolygonDrawToolboxCurrentStrokeOpacity: getPolygonDrawToolboxCurrentStrokeOpacity(state),
});

const mapDispatchToProps = {
    setDrawerToolboxClickedState: setDrawerToolboxClickedState,
    setLineDrawToolboxState: setLineDrawToolboxState,
    setEraserClickedState: setEraserClickedState,
    setRulerClickedState: setRulerClickedState,
    setCommentToolboxState: setCommentToolboxState,
    setPolygonDrawToolboxState: setPolygonDrawToolboxState,
    setPolygonDrawToolboxPolygonsState: setPolygonDrawToolboxPolygonsState,
    setPolygonDrawToolboxColors: setPolygonDrawToolboxColors,
    setMapboxDrawToolboxGeoJSONState: setMapboxDrawToolboxGeoJSONState,
    setStickyNotesClickedState: setStickyNotesClickedState,
};

export default connect(mapStateTopProps, mapDispatchToProps)(memo(PolygonDrawToolbox));
