export const LOADING_SUBJECTS = "load-subjects";
export const SET_SUBJECTS = "set-subjects";

export const LOADING_FILTERED_SUBJECTS = "load-filtered-subjects";

export const SET_SUBJECTS_SETTINGS = "set-subject-settings";
export const SET_FILTERED_SUBJECTS = "set-filtered-subject";

export const LOADING_GRADES = "load-grades";
export const SET_GRADES = "set-grades";

export const LOADING_FILTERED_GRADES = "load-filtered-grades";
export const SET_FILTERED_GRADES = "set-filtered-grades";

export const LOADING_SCHOOLS = "load-schools";
export const SET_SCHOOLS = "set-schools";

export const LOADING_BOOKMARKS = "load-bookmarks";
export const SET_BOOKMARKS = "set-bookmarks";

export const LOADING_PRESENTATION = "load-presentation";

export const SET_PRESENTATION = "set-presentation";
export const ADD_PRESENTATION_FILTERED_TOTAL = "set-presentation-filtered-total";
export const ADD_PRESENTATION = "add-presentation";
export const LOADING_STATES = "load-states";
export const SET_STATES = "set-states";

export const LOADING_TOPICS = "load-topics";
export const SET_TOPICS = "set-topics";
export const SET_TOPICS_NAVIGATION = "set-topics-navigation";
export const SET_TOPIC_ID = "SET_TOPIC_ID";

export const LOADING_COUNTRIES = "load-countries";
export const SET_COUNTRIES = "set-countries";

export const LOADING_REGIONS = "load-regions";
export const SET_REGIONS = "set-regions";

export const LOADING_LESSONS = "load-lessons";
export const SET_LESSONS = "set-lessons";
export const SET_LESSON_NAVIGATION_DATA = "set-lesson-navigation-data";
export const SET_LESSONS_TITLE = "SET_LESSONS_TITLE";
export const SET_LESSON_DATA = "SET_LESSON_DATA";

export const SET_USER = "set-user";
export const SET_TOKENS = "set-tokens";
export const LOGOUT_USER = "logout";

export const SET_MENU = "set-menu";
// export const SET_SLIDES= "set-slides";
export const SET_SLIDES_ITEMS= "set-slides-items";
export const ADD_SLIDE= "add-slide";
export const SET_SELECTED_SLIDE= "set-selected-slide";
export const SET_SLIDES_LOADING= "set-slides-loading";
export const SET_FILTERED_TOTAL_SLIDES= "set-filtered-total-slides";
export const REPLACE_SLIDE= "replace-slide";
export const SET_PLAY_SLIDES= "set-play-slides";
export const SET_SLIDES_PAGE= "set-slides-page";
export const SET_SEE_ALL_SLIDES= "set-see-all-slides";
export const SET_SELECTED_PRESENTATION = "set-selected-presentation"
export const SET_EXTERNAL_PRESENTATION_MOD_DATA = "set-external-presentation-mod-data";
export const SET_PRESENTATION_FILTERED_TOTAL = "set-presentation-filtered-total"
export const SET_PRESENTATION_MODE = "set-presentation-mode"
export const SET_PRESENTATION_MODE_LOADING = "set-presentation-mode-loading"
export const SET_IS_PRESENTATION_MODE = "set-is-presentation-mode"
export const SET_ABOUT = "set-about";

export const SET_SPRITE_DATA = "set-sprite-data";
export const SET_SPRITE_URL = "set-sprite-url";
export const SET_SPRITE_BASE64 = "set-sprite-base64";
export const SET_MAP_STYLE = "set-map-style";
export const SET_SEARCH = "set-search";

export const SET_PAINTER_START = "SET_PAINTER_START";
export const SET_PAINTER_GEO_JSON = "SET_PAINTER_GEO_JSON";
export const SET_PAINTER_PREV_DATA = "SET_PAINTER_PREV_DATA";
export const SET_PAINTER_PREV_LINE_NAME_DATA =
  "SET_PAINTER_PREV_LINE_NAME_DATA";
export const SET_PAINTER_LAYER_LINE_NAME_DATA =
  "SET_PAINTER_LAYER_LINE_NAME_DATA";
export const SET_QUIZ_POPUP = "set-quiz-popup";
export const SET_HINTS = "set-hints";
export const SET_MAP_REQUIREMENT = "set-map-requirement";
export const SET_MENU_EXPEND = "set-menu-expend";
export const SET_TIMELINE_EXPEND = "set-timeline-expend";

export const CHANGE_MAP_STYLES = "change-map-styles";
export const SET_MAP_STYLES = "set-map-styles";
export const SET_MAP_STYLES_EXTRA_LAYERS = "set-map-styles-extra-layers";
export const SET_MAP_CONFIGURATIONS = "set-map-configurations";
export const CHANGE_MAP_STYLES_ST = "CHANGE_MAP_STYLES_ST";
export const CHANGE_MAP_STYLES_DARK = "change-map-styles-dark";

export const CHANGE_MAP_STYLES_LIST = "change-map-styles-list";
export const CHANGE_MAP_REQUIREMENTS = "change-map-requirements";

export const LOADING_ARTICLES = "load-articles";
export const SET_ARTICLES = "set-articles";
export const SET_SELECTED_ARTICLE = "set-selected-article";

export const LOADING_ARTICLES_ALL = "load-articles-all";
export const SET_ARTICLES_ALL = "set-articles-all";
export const SET_SELECTED_ARTICLE_ALL = "set-selected-article-all";
export const SET_ARTICLE_IS_LIKED = "set-article-is-liked";

export const SET_ARTICLE_FONT_SIZE = "set-article-font-size";
export const SET_ARTICLE_READ_MORE_FONT_SIZE = "set-article-read-more-font-size";
export const CHANGE_MAP_ZOOM = "set-map-zoom";

export const CHANGE_MAP_BOUNCE = "set-map-bounce";

export const CHANGE_MAP_ZOOM_TO_ACTION = "set-map-zoom-to-action";

export const LOADING_LAMP = "load-lamp";
export const SET_LAMP = "set-lamp";
export const SET_SORTED_TOOLTIP_SELECT_ITAM = "SET_SORTED_TOOLTIP_SELECT_ITAM";
export const SET_LAMP_MODAL_STATE = "SET_LAMP_MODAL_STATE";
export const SET_READ_MORE_MODAL_STATE = "SET_READ_MORE_MODAL_STATE";
export const SET_GALLERY_MODAL_STATE = "SET_GALLERY_MODAL_STATE";
export const SET_GALLERY_INDEX = "SET_GALLERY_INDEX";
export const SET_GALLERY_TILI_INDEX = "SET_GALLERY_TILI_INDEX";
export const SET_GALLERY_FULL_SCREEN = "SET_GALLERY_FULL_SCREEN";
export const SET_ARTICLE_POSITION = "SET_ARTICLE_POSITION";
export const SET_CLOSED_BLOCKS = "SET_CLOSED_BLOCKS";
export const SET_GALLERY_ARTICLE_ID = "SET_GALLERY_ARTICLE_ID";
export const SET_LAMP_MODAL_STATE_INDEX = "SET_LAMP_MODAL_STATE_INDEX";
export const SET_LAMP_MODAL_DATA = "SET_LAMP_MODAL_DATA";

export const LOADING_QUIZ = "load-quiz";
export const SET_QUIZ = "set-quiz";
export const SET_SELECTED_QUIZ_MODAL_DATA = "set-selected-quiz";
export const SET_SORTED_TOOLTIP_SELECT_ITAM_QUIZ =
  "SET_SORTED_TOOLTIP_SELECT_ITAM_QUIZ";
export const SET_QUIZ_MODAL_STATE = "SET_QUIZ_MODAL_STATE";
export const SET_QUIZ_MODAL_DATA = "SET_QUIZ_MODAL_DATA";

export const SET_CATEGORIES = "set-categories";
export const SET_FILTERED_CATEGORIES = "set-filtered-categories";
export const SET_FILTERED_CATEGORIES_ST = "set-filtered-categories-st";

export const SET_TIMELINE_OPTIONS = "set-timeline-options";

export const SET_NEXT_LESSON = "set-next-lesson";
export const SET_PREV_LESSON = "set-prev-lesson";

export const SET_TIMELINE_ITEMS = "set-timeline-items";
export const SET_MIDDLE_TIMELINE_ITEMS = "set-middle-timeline-items";
export const SET_MAX_MIDDLE_TIMELINE_ITEMS = "set-middle-max-timeline-items";
export const SET_TIMELINE_GROUPS = "set-timeline-groups";
export const LOADING_TIMELINE = "load-timeline";
export const LOADING_MIDDLE_TIMELINE = "load-middle-timeline";
export const SET_TIMELINE_ARTICLE = "set-timeline-article";

export const SET_MAP_LOADING = "set-map-loading";
export const SET_MAP_SELECTED_CLUSTER = "set-map-selected-cluster";

export const SET_LESSON_ID = "set-lesson-id";

export const SET_RESET_STATE = "SET_RESET_STATE";

export const SET_GEO_JSON = "set-geo-json";
export const LOADING_GEO_JSON = "load-geo-json";
export const SET_TIME_GEO_JSON = "set-time-geo-json";
export const LOADING_TIME_GEO_JSON = "load-time-geo-json";

export const SET_BASE_MAP_IS_LIGHT =  "set-base-map-is-light";
export const SET_BASE_MAP_GROUP_ID_ST = "set-base-map-group-id-st";
export const SET_SCREENSHOT_BUTTON_STATE = "SET_SCREENSHOT_BUTTON_STATE";

export const SET_MAPBOX_DRAW_TOOLBOX_GEO_JSON_STATE = "SET_MAPBOX_DRAW_TOOLBOX_GEO_JSON_STATE";
export const SET_LINE_DRAW_TOOLBOX_STATE = "SET_LINE_DRAW_TOOLBOX_STATE";
export const SET_LINE_DRAW_TOOLBOX_WEIGHT = "SET_LINE_DRAW_TOOLBOX_WEIGHT";
export const SET_LINE_DRAW_TOOLBOX_CURRENT_COLOR = "SET_LINE_DRAW_TOOLBOX_CURRENT_COLOR";
export const SET_LINE_DRAW_TOOLBOX_COLOR_PICKER_STATE = "SET_LINE_DRAW_TOOLBOX_COLOR_PICKER_STATE";
export const SET_LINE_DRAW_TOOLBOX_COLORS = "SET_LINE_DRAW_TOOLBOX_COLORS";

export const SET_POLYGON_DRAW_TOOLBOX_CURRENT_STROKE_OPACITY = "SET_POLYGON_DRAW_TOOLBOX_CURRENT_STROKE_OPACITY";
export const SET_POLYGON_DRAW_TOOLBOX_CURRENT_FILL_OPACITY = "SET_POLYGON_DRAW_TOOLBOX_CURRENT_FILL_OPACITY";
export const SET_POLYGON_DRAW_TOOLBOX_CURRENT_STROKE_COLOR = "SET_POLYGON_DRAW_TOOLBOX_CURRENT_STROKE_COLOR";
export const SET_POLYGON_DRAW_TOOLBOX_CURRENT_FILL_COLOR = "SET_POLYGON_DRAW_TOOLBOX_CURRENT_FILL_COLOR";

export const SET_STICKY_NOTES_CLICKED_STATE = "SET_STICKY_NOTES_CLICKED_STATE";
export const SET_STICKY_NOTES_MARKERS_LOADING_STATE = "set-sticky-notes-markers-loading-state";
export const SET_STICKY_NOTES_MARKERS_STATE = "set-sticky-notes-markers-state";

export const SET_POLYGON_DRAW_TOOLBOX_STATE = "SET_POLYGON_DRAW_TOOLBOX_STATE";
export const SET_POLYGON_DRAW_TOOLBOX_LINES = "SET_POLYGON_DRAW_TOOLBOX_LINES";

export const SET_POLYGON_DRAW_TOOLBOX_COLOR_PICKER_STATE = "SET_POLYGON_DRAW_TOOLBOX_COLOR_PICKER_STATE";
export const SET_POLYGON_DRAW_TOOLBOX_COLORS = "SET_POLYGON_DRAW_TOOLBOX_COLORS";

export const SET_MAP_STATE_DATA = "SET_MAP_STATE_DATA";
export const ADD_MAP_STATE_DATA = "ADD_MAP_STATE_DATA";
export const SET_MAP_STATE_SINGLE_DATA = "SET_MAP_STATE_SINGLE_DATA";
export const SET_OPEN_CATEGORY_ID = "SET_OPEN_CATEGORY_ID";
export const SET_OPEN_SUB_CATEGORY_ID = "SET_OPEN_SUB_CATEGORY_ID";
export const SET_MAP_REQUIREMENT_SCREEN_SHOT =
  "SET_MAP_REQUIREMENT_SCREEN_SHOT";
export const CHANGE_MAP_REQUIREMENTS_SCREEN_SHOT =
  "CHANGE_MAP_REQUIREMENTS_SCREEN_SHOT";
export const SET_CATEGORIES_ARTICLE_ITEM_ID = "SET_CATEGORIES_ARTICLE_ITEM_ID";
export const SET_LAMP_DATA_FILTERED_SCREEN_SHOT =
  "SET_LAMP_DATA_FILTERED_SCREEN_SHOT";
export const SET_LAMP_SORTED_TOOLTIP_STATE = "SET_LAMP_SORTED_TOOLTIP_STATE";
export const SET_TEST_FILTERED_CATEGORIES = "SET_TEST_FILTERED_CATEGORIES";
export const SET_LAMP_SELECTED_ID = "SET_LAMP_SELECTED_ID";
export const SET_MAP_STYLES_ID = "SET_MAP_STYLES_ID";
export const SET_MAP_STYLES_DARK_ID = "SET_MAP_STYLES_DARK_ID";
export const SET_START_POINTER_CLICK_STATE = "SET_START_POINTER_CLICK_STATE";
export const SET_PAINTER_COLOR = "SET_POINTER_COLOR";
export const SET_PAINTER_SIZE = "SET_POINTER_SIZE";
export const SET_RULER_GEOJSON_DATA_ST = "SET_RULER_GEOJSON_DATA_ST";
export const SET_RULER_CLICKED_STATE = "SET_RULER_CLICKED_STATE";
export const SET_RULER_POPUP_SHOW_ST = "SET_RULER_POPUP_SHOW_ST";
export const SET_IS_ALL_TOOLBOX_STATES_LOADED = "SET_IS_ALL_TOOLBOX_STATES_LOADED";
export const SET_SELECTED_TOOL_UNDO_REDO = "SET_SELECTED_TOOL_UNDO_REDO";
export const SET_UNDO_REDO_DATA = "SET_UNDO_REDO_DATA";
export const SET_ERASER_CLICKED_STATE = "SET_ERASER_CLICKED_STATE";
export const SET_COMMENT_TOOLBOX_CLICKED_STATE = "SET_COMMENT_TOOLBOX_CLICKED_STATE";
export const SET_COMMENT_TOOLBOX_MARKERS_STATE = "SET_COMMENT_TOOLBOX_MARKERS_STATE";
export const SET_COMMENT_TOOLBOX_MARKERS_LOADING_STATE = "SET_COMMENT_TOOLBOX_MARKERS_LOADING_STATE";
export const SET_PAINTER_GEOJSON_DATA_ST = "SET_PAINTER_GEOJSON_DATA_ST";
export const SET_MAP_STYLES_STATUS = "SET_MAP_STYLES_STATUS";

//compass
export const SET_NEW_ANGLE = "SET_NEW_ANGLE";
export const SET_NEW_ROTATE = "SET_NEW_ROTATE";
export const SET_COMPASS_COORDINATES = "SET_COMPASS_COORDINATES";
export const SET_RESET_COMPASS = "SET_RESET_COMPASS";
export const SET_DISABLE_COMPASS = "SET_DISABLE_COMPASS";

//timeline

export const SET_TIMELINE_ZOOM_ST = "SET_TIMELINE_ZOOM_ST";
export const SET_TIMELINE_ZOOM_INDEX_ST = "SET_TIMELINE_ZOOM_INDEX_ST";
export const SET_TIMELINE_MOVE_COUNT_ST = "SET_TIMELINE_MOVE_COUNT_ST";
export const SET_TIMELINE_CURSOR_STATUS_ST = "SET_TIMELINE_CURSOR_STATUS_ST";
export const SET_FIRST_TIMELINE_CURSOR_STATUS_ST = "SET_FIRST_TIMELINE_CURSOR_STATUS_ST";
export const SET_TIMELINE_EVENT_DATA_ST = "SET_TIMELINE_EVENT_DATA_ST";
export const SET_TIMELINE_MOVE_POSITION = "SET_TIMELINE_MOVE_POSITION";
export const SET_TIMELINE_MOVE_STATUS = "SET_TIMELINE_MOVE_STATUS";
export const SET_TIMELINE_ZOOM_LEVEL = "SET_TIMELINE_ZOOM_LEVEL";
export const SET_SCREEN_SHOT_LOADING = "SET_SCREEN_SHOT_LOADING";
export const SET_MAP_CENTER_POSITION = "SET_MAP_CENTER_POSITION";
export const SET_MAP_CENTER_POSITION_STATUS = "SET_MAP_CENTER_POSITION_STATUS";
export const SET_SCREENSHOT_SEND_DATA = "SET_SCREENSHOT_SEND_DATA";
export const SET_SCREENSHOT_INTERMEDIATE_DATA =
  "SET_SCREENSHOT_INTERMEDIATE_DATA";
export const SET_SCREENSHOT_BUTTON_CLICK = "SET_SCREENSHOT_BUTTON_CLICK";
export const SET_MAP_MARKER_INFO = "SET_MAP_MARKER_INFO";
export const SET_SCREENSHOT_LOADER = "SET_SCREENSHOT_LOADER";
export const SET_REMOVE_CARD_ID = "SET_REMOVE_CARD_ID";

export const SET_AUDIO_PLAYING = "set-audio-playing";

export const SET_TIMELINE_MOVE_DATA_STATUS_ST =
  "SET_TIMELINE_MOVE_DATA_STATUS_ST";
export const SET_TIMELINE_MOVE_DATA_ST = "SET_TIMELINE_MOVE_DATA_ST";
export const SET_TOPIC_STATIC_TITLE_ST = "SET_TOPIC_STATIC_TITLE_ST";
export const SET_TIMELINE_CLICK_STATE = "SET_TIMELINE_CLICK_STATE";
export const SET_MOBILE_MENU_CLOSE = "SET_MOBILE_MENU_CLOSE";

export const SET_TIMELINE_HEIGHT = "SET_TIMELINE_HEIGHT";
export const SET_SHOW_NOTIFICATION = "SET_SHOW_NOTIFICATION";

export const LOADING_LEGENDS = "load-legends";
export const SET_LEGENDS = "set-legends";
export const SET_FILTERED_LEGENDS = "set-filterds-legends";
