import moment from "moment/moment";
import {toArray} from "lodash";
import i18n from "i18next";
export const removeExtraSpaces = (str) => {
  return str.replace(/(?<=^|\s)\s+(?=\s|$)/g, '');
};
export const cutWord = (str) => {
  if (str.length > 3) {
    str = str.substr(0, 3); // Extracts a substring up to the maximum length
    str = str.trim() + "."; // Trims any white space and adds a dot to the end
  }

  return str;
};
export const changeLng = () => {
  const currentLng = i18n.language
  i18n.changeLanguage(currentLng === 'en' ? 'hy' : 'en');
};
export const delay = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export const genPatchBody = (data) =>{
  return data.map(el=>{
    el = {
        "path": `/${el.key}`,
        "op": "add",
        "value": el.value
      }
    return el
  })
}
export const clusteriser = function(timeline){
  this.timeline = timeline;
  this.step = 0;
  this.clusters = [];
  this.getDiffDate = function(date1, date2){
    var second=1000;
    var minute = second*60;
    var hour = minute*60;
    var day = hour*24;
    var month = day*31;
    var year = month*12;

    // Convert both dates to milliseconds
    var diff = date2.getTime() - date1.getTime();

    // Convert back to components and return
    return {
      years: Math.round(diff/year),
      months: Math.round(diff/month),
      days: Math.round(diff/day),
      hours: Math.round(diff/hour),
      minutes: Math.round(diff/minute)
    };
  }

  //Get the scale between two date
  //We can use the scale information from timeline api in order to know how to cluster data
  this.getScale = function(start, end) {
    var diff = this.getDiffDate(start, end);

    if (diff.months > 12*2) {
      return 'year';
    }
    else if (diff.days >31) {
      return 'month';
    }
    else if (diff.hours >24) {
      return 'day';
    }
    else if (diff.minutes >60) {
      return 'hour';
    }
    else {
      return 'second';
    }
  }
  this.convertToFourDigitYear = function (year) {
    var isNegative = year < 0;
    var absoluteYear = Math.abs(year);
    var paddedYear = absoluteYear.toString().padStart(4, '0');

    var date = new Date('0001-01-01');
    date.setFullYear(paddedYear);
    if (isNegative) {
      // Convert negative year to BCE
      date.setFullYear(-paddedYear);
    }

    return date;
  }

  this.getClusters = function(dataItems) {
    if (!Array.isArray(dataItems) || dataItems.length === 0) {
      return [];
    }

    if (this.timeline?.timeAxis?.step?.step === this.step) {
      return this.clusters;
    }
    this.step = this.timeline?.timeAxis?.step?.step;
    const result = {};

    const sortData = dataItems.sort((a, b) => a.start - b.start);
    const generaitGroupItems = (arr, result) => {
      let id = new Date(arr[0].start).getFullYear();

      let fullYear = new Date(arr[0].start).getFullYear();
      let newArr = [...arr];
      let filterIds = [];

      if (arr[0].groupId) {
        arr.forEach((item, idx) => {
          item.inGroup = false;
          const groupIdKey = id + '-group' + item.groupId;

          if (result[groupIdKey] === void 0) {
            result[groupIdKey] = {
              startFrom: item.start,
              endTo: item.end,
              items: [],
              count: 0,
              openMiddleTimeline:true
            };
          }
          const rangeEnd = {
            1000:[fullYear+250, 0, 0],
            500:[fullYear+150, 0, 0],
            100: [fullYear + 15, 1, 0],
            50: [fullYear + 10, 1, 0],
            10: [fullYear + 5, 1, 0],
            5: [fullYear + 3, 6, 0],
            1: [fullYear, 1, 0]

          };
          const rangeStart = {
            1000:[fullYear-250, 0, 0],
            500:[fullYear-150, 0, 0],
            100: [fullYear - 15, 1, 0],
            50: [fullYear - 10, 1, 0],
            10: [fullYear - 5, 1, 0],
            5: [fullYear - 3, 6, 0],
            1: [fullYear - 1, 12, 0]

          };
          const start = this.convertToFourDigitYear(...rangeStart[this.step]);
          const end = this.convertToFourDigitYear(...rangeEnd[this.step]);
          if ((new Date(item.start) >= start && end >= new Date(item.start)) ||
              (new Date(item.end) >= start && end >= new Date(item.start))) {
            const group = result[groupIdKey];
            if (group.startFrom >= item.start) {
              group.startFrom = item.start;
            }

            if (group.endTo <= item.end) {
              group.endTo = item.end;
            }
            const { startFrom, endTo } = group;
            fullYear =  new Date(startFrom).getFullYear() + ((new Date(endTo).getFullYear() - new Date(startFrom).getFullYear()) / 2);
            group.groupId = item.groupId;
            group.id = groupIdKey;
            group.className = 'related-cluster';
            group.color = 'red';
            group.start = this.convertToFourDigitYear(fullYear);
            group.end = this.convertToFourDigitYear(fullYear);
            group.scaleStart = startFrom.getFullYear() - 5
            group.scaleEnd = endTo.getFullYear() + 5
            if(!item.openMiddleTimeline){
              group.openMiddleTimeline = item.openMiddleTimeline
            }
            group.bounds = item.bounds
            group.items.push(item);
            group.count++;
            filterIds.push(item.id);
            if(group.count > 1) item.inGroup = true;
          }
        });

        if (filterIds.length === 0) {
          filterIds.push(arr[0].id);
        }
      } else {
        filterIds.push(arr[0].id);
      }

      newArr = newArr.filter(elem => !filterIds.includes(elem.id));

      if (newArr.length > 0) {
        generaitGroupItems(newArr, result);
      }
      return result;
    };

    this.clusters = Object.values(generaitGroupItems(sortData, result)).reduce((acum, el,idx) => {
      el.id = idx
      if (el.count > 1) {
        acum.push(el);
      } else {
        acum.push(...el.items);
      }
      return acum;
    }, []);
    return this.clusters;
  }
  this.getClusterExpansionZoom = function(clusterId) {
      const item = this.clusters?.find(el=>el.id === clusterId)
  }
}
export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
export const getLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};
export const updateURLParameter = (url, param, paramValue) => {
  const urlParams = new URLSearchParams(url.search);
  urlParams.set(param, paramValue);
  return `${url.origin}${url.pathname}?${urlParams.toString()}${url.hash}`;
};
export const isValidArray = (arr) => {
  return arr.every(element => {
    if (element?.geometry) {
      return true;
    }
    return false;
  });
}