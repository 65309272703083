import React, {memo, useCallback, useEffect, useRef} from 'react';
import * as turf from '@turf/turf';
import { v4 as uuidv4 } from 'uuid';
import {isMobile} from "react-device-detect";
import {connect} from "react-redux";

import {
    getMapStyledDarkId,
    getMobileMenuState,
    getPainterStartData,
    getRulerClickedState,
    getRulerPopupShowST,
    getDrawerToolboxClickedState,
    getPainterGeoJsonDataST,
    getEraserClickedState,
} from "../../../store/selectors";
import {
    setCommentToolboxState,
    setDrawerToolboxClickedState,
    setEraserClickedState,
    setLineDrawToolboxState, setPolygonDrawToolboxState,
    setRulerClickedState, setStickyNotesClickedState,
} from "../../../store/actions/painterStart";

import {dispatchUndoRedoData, setPainterGeoJsonDataST} from "../../../store/actions/mapStateAction";

import {changeCursorIcon, CURSOR_TYPE} from "../../../shared/mockData";
import EraserIcon from "../../../assets/imgs/PaintBar/eraser-icon.svg";
import {DRAWER_TOOLBOX_LAYER, ERASER_TOOLBOX_LAYER, MINIMAL_COORDINATES_LENGTH_COUNT} from "../DrawerToolbox/constants";

const EraserToolbox = (props) => {
    const {
        getPainterStartData,
        setDrawerToolboxClickedState,
        setPainterGeoJsonDataST,
        setCommentToolboxState,
        setLineDrawToolboxState,
        setRulerClickedState,
        setEraserClickedState,
        setStickyNotesClickedState,
        setPolygonDrawToolboxState,
        dispatchUndoRedoData,
        getEraserClickedState,
        map,
    } = props

    const radiusOfCircle = useRef(40);

    const getDrawingSource = () => {
        return map.getSource(DRAWER_TOOLBOX_LAYER);
    };

    const getEraserSource = () => {
        return map.getSource(ERASER_TOOLBOX_LAYER);
    };

    const mouseMoveEraserHandler = useCallback((e) => {
        const { lng, lat } = e.lngLat;
        const sizeInPixels = radiusOfCircle.current; // Adjust this size as needed

        const centerMercator = map.project([lng, lat]);
        const nwMercator = [centerMercator.x - sizeInPixels / 2, centerMercator.y - sizeInPixels / 2];
        const seMercator = [centerMercator.x + sizeInPixels / 2, centerMercator.y + sizeInPixels / 2];

        const nw = map.unproject(nwMercator);
        const se = map.unproject(seMercator);
        const radiusLngLat = [(nw.lng + se.lng) / 2, nw.lat];
        const radius = turf.distance([lng, lat], radiusLngLat, { units: 'meters' });

        const circleOptions = {
            steps: 64,
            units: 'meters',
        }

        if (!circleFeatureRef.current) {
            circleFeatureRef.current = turf.circle([lng, lat], radius, circleOptions)
        } else {
            circleFeatureRef.current.geometry.coordinates = (turf.circle([lng, lat], radius, circleOptions)).geometry.coordinates
        }

        const drawingSource = getDrawingSource();
        const linesSource = drawingSource._data;
        const newLineFeatures = [];

        const seperatedFeatures = map.queryRenderedFeatures([nwMercator, seMercator], {
            layers: [DRAWER_TOOLBOX_LAYER],
        })

        if(!seperatedFeatures.length) {
            return;
        }

        linesSource.features.forEach((lineFeature) => {
            if (lineFeature.geometry.coordinates.length <= MINIMAL_COORDINATES_LENGTH_COUNT) return;
            const currentLine = turf.lineString(lineFeature.geometry.coordinates, lineFeature.properties);
            if (!turf.booleanDisjoint(currentLine, circleFeatureRef.current)) {
                const splitLines = turf.lineSplit(currentLine, circleFeatureRef.current);

                splitLines.features.forEach((splitLine) => {
                    if (!turf.booleanWithin(splitLine, circleFeatureRef.current)) {
                        if (splitLine.geometry.coordinates.length <= MINIMAL_COORDINATES_LENGTH_COUNT) return;
                        splitLine.id = uuidv4();
                        splitLine.properties = { ...lineFeature.properties, id: splitLine.id };
                        newLineFeatures.push(splitLine);
                    }
                });
            } else {
                newLineFeatures.push(lineFeature);
            }
        });

        drawingSource.setData(turf.featureCollection(newLineFeatures));
    }, [radiusOfCircle.current]);

    const mouseDownEraserHandler = (e) => {
        map.on('mouseout', documentOverHandler);
        map.on(`${isMobile ? 'touchmove' : 'mousemove'}`, mouseMoveEraserHandler)
    }
    const mouseUpEraserHandler = (e) => {
        map.off('mouseout', documentOverHandler);
        map.off(`${isMobile ? 'touchmove' : 'mousemove'}`, mouseMoveEraserHandler)
        setPainterGeoJsonDataST({...getDrawingSource()._data});
        dispatchUndoRedoData({...JSON.parse(JSON.stringify(getDrawingSource()._data))});
    }

    const mouseDownEraserHandlerRef = useRef(mouseDownEraserHandler); // function referral link
    const mouseUpEraserHandlerRef = useRef(mouseUpEraserHandler); // function referral link
    const circleFeatureRef = useRef(null);

    const handleIconClick = () => {
        setEraserClickedState(!getEraserClickedState);
        setDrawerToolboxClickedState(false);
        setStickyNotesClickedState(false);
        setLineDrawToolboxState(false);
        setPolygonDrawToolboxState(false);
        setCommentToolboxState(false);
        setRulerClickedState(false);
    };

    const documentOverHandler = useCallback(() => {
        function removeListenerFromDocument(){
            document.removeEventListener('mouseup', offMouseOverFunctionOfMap)
        }
        function offMouseOverFunctionOfMap () {
            map.off('mouseover', removeListenerFromDocument)
            mouseUpEraserHandlerRef.current()
        }
        document.addEventListener('mouseup', offMouseOverFunctionOfMap,{ once: true });
        map.once('mouseover', removeListenerFromDocument)
    }, []);

    useEffect(() => {
        if (getEraserClickedState && getPainterStartData) {
            setTimeout(() => {
                changeCursorIcon(CURSOR_TYPE.ERASER);
                map["dragPan"].disable();
            }, 50);
            // touchstart
            // touchmove
            // touchend
            map.on(`${isMobile ? 'touchstart' : 'mousedown'}`, mouseDownEraserHandlerRef.current);
            map.on(`${isMobile ? 'touchend' : 'mouseup'}`, mouseUpEraserHandlerRef.current);
        } else {
            changeCursorIcon();
            map["dragPan"].enable();
            map.off(`${isMobile ? 'touchstart' : 'mousedown'}`, mouseDownEraserHandlerRef.current);
            map.off(`${isMobile ? 'touchend' : 'mouseup'}`, mouseUpEraserHandlerRef.current);

            if(getEraserSource()) {
                getEraserSource().setData({
                    type: 'FeatureCollection',
                    features: []
                });
            }

            setEraserClickedState(false);
        }
    }, [getEraserClickedState, getPainterStartData]);

    return (
        <div
            onClick={handleIconClick}
            className={`pain_items ${getEraserClickedState ? "button_active" : ""}`}
        >
            <img src={EraserIcon} alt="" className="icon_img"/>
        </div>
    );
};

const mapStateToProps = (state) => ({
    getRulerClickedState: getRulerClickedState(state),
    getPainterStartData: getPainterStartData(state),
    getRulerPopupShowST: getRulerPopupShowST(state),
    getMapStyledDarkId: getMapStyledDarkId(state),
    getMobileMenuState: getMobileMenuState(state),
    getDrawerToolboxClickedState: getDrawerToolboxClickedState(state),
    getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
    getEraserClickedState: getEraserClickedState(state),
})
const mapDispatchToProps = {
    setCommentToolboxState: setCommentToolboxState,
    setLineDrawToolboxState: setLineDrawToolboxState,
    setDrawerToolboxClickedState: setDrawerToolboxClickedState,
    setRulerClickedState: setRulerClickedState,
    setEraserClickedState: setEraserClickedState,
    setStickyNotesClickedState: setStickyNotesClickedState,
    setPolygonDrawToolboxState: setPolygonDrawToolboxState,
    setPainterGeoJsonDataST: setPainterGeoJsonDataST,
    dispatchUndoRedoData: dispatchUndoRedoData,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(EraserToolbox));
